@import '../../AppVariables';

select[name="customerCode"], {
  margin-top: .25rem;
  font-size: .85rem;
  @if $darkMode {
    background-color: $abLightBlueTransparent;
  }
  @else {
    background-color: $abBlueTransparent;
  }
  &:focus,
  &:active {
    @if $darkMode {
      background-color: $abLightBlueTransparent;
    }
    @else {
      background-color: $abBlueTransparent;
    }
  }
}
