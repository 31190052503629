@import url('https://fonts.googleapis.com/css?family=Assistant:300,400,600,700&display=swap');
@import './AppVariables';

* {
  font-family: 'Assistant', sand-serif;
}

//Safari svg height
@supports (-webkit-backdrop-filter: blur(1px)) {
  svg {
    height: 100%;
  }
}

//Background gradient
.bkg-gradient {
  background-image: linear-gradient(125deg, rgba(0,101,167, 1) 0%, rgba(41,139,138, 1) 100%);
}

.transitioned-component-enter {
  opacity: 0;
}
.transitioned-component-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.transitioned-component-exit {
  opacity: 1;
}
.transitioned-component-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}
