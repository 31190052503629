$abBlue: #0065a7;
$black: #333;

.utility-modal {
  color: $abBlue;

  .modal-title {
    font-weight: bold;
  }

  .modal-body {
    color: $black;

    .store-link {
      margin-top: 1.5rem;
      font-weight: bold;

      a {
        text-decoration: underline;
        color: $black;
      }

      svg {
        color: $black;
        font-size: 1.25rem;
      }
    }
  }
}
