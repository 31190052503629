$darkMode: false !default;

//Color variables
$white: #f1f1f1;
$textBlack: #666;
$lightGrey: #ddd;
$dashBoardBkg: #F2F4F4;
$greenOption: green;
$abGreen: #5db436;

$abLightBlue: #2bc9ca;
$abMagenta: #fd13df;
$abLightBlueTransparent: rgba(152, 233, 234, 0.3);

$abBlue: #0065a7;
$abYellow: #ffc525;
$abBlueTransparent: #d3e5f5;

//Electrical
$darkGreen: #16845F;
$mediumGreen: #3F9F4A;
$lightGreen: #67BA34;

//Gas
$darkBlue: #0164A7;
$mediumBlue: #0181d8;
$lightBlue: #009FF8;

$cardBoxShadow: #999;
$cardBorderColor: #e8e8e8;
